.inventory-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}


.card {

  width: 100%;
  box-sizing: border-box;
  height: 650px;
  display: flex;
  flex-direction: column;
}


a {
  text-decoration: none;
  color: inherit;
}
.image {
  width: 400px;
  height: 400px;
  margin: 0 auto;
}
.button{

  margin: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
